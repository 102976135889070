<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Randevular</h3>
        
      </div>
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-sm-12 col-md-3 col-xl-3 mt-2">
            <button
              :class="
                'btn w-100 btn-primary' + (selectedStatus == 0 ? ' active' : '')
              "
              @click="statusChange(0)"
            >
              ONAY BEKLEYEN
            </button>
          </div>
          <div class="col-sm-12 col-md-3 col-xl-3 mt-2">
            <button
              :class="
                'btn w-100 btn-primary' + (selectedStatus == 1 ? ' active' : '')
              "
              @click="statusChange(1)"
            >
              ONAYLI
            </button>
          </div>
          <div class="col-sm-12 col-md-3 col-xl-3 mt-2">
            <button
              :class="
                'btn w-100 btn-primary' + (selectedStatus == 2 ? ' active' : '')
              "
              @click="statusChange(2)"
            >
              İPTAL EDİLEN
            </button>
          </div>
          <div class="col-sm-12 col-md-3 col-xl-3 mt-2">
            <button
              :class="
                'btn w-100 btn-primary' + (selectedStatus == 3 ? ' active' : '')
              "
              @click="statusChange(3)"
            >
              Ön Randevular
            </button>
          </div>
        </div>
        <PreAppointmentListComponent v-if="selectedStatus==3" />
        <AppointmentListComponent v-if="selectedStatus!=3" :sStatus="selectedStatus" />
        
      </div>
    </div>
  </div>
</template>
<style scoped>
.active{
  border-bottom: 5px solid #ffffff87 !important;
    padding-bottom: 12px;
    transition:.15s ease-in-out;
}
</style>
  <script>

import PreAppointmentListComponent from './Components/PreAppointmentListComponent.vue';
import AppointmentListComponent from './Components/AppointmentListComponent.vue';
export default {
  created() {
    document.title = "Randevularım";
  },
  data() {
    return {
      selectedStatus: 0,
    };
  },
  methods: {
    statusChange(i) {
      this.selectedStatus = i;
    },
    
  },
  components: { PreAppointmentListComponent,AppointmentListComponent },
  mounted() {},
};
</script>