<template>
  <div class="row">
    <div class="col-12">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            id="searchAppointment"
            placeholder="Randevularda Ara"
          />
          <button
            type="button"
            class="btn btn-primary"
            @click="searchAppointment"
          >
            ARA
          </button>
        </div>
    </div>
    <router-link
      class="card"
      v-for="(item, i) in list"
      :style="'background:' + item.color"
      :to="
        '/profile/appointments/detail/' + item.member_id + '/' + item.user_id
      "
      :key="i"
    >
      <div class="card-header">
        <div class="row w-100">
          <div class="col-sm-12 col-md-4 col-xl-4">
            <b>Danışan:</b>{{ item.fullname }}
          </div>
          <div class="col-sm-12 col-md-4 col-xl-4">
            <b>Terapist:</b>{{ item.expert_name }}
          </div>
          <div class="col-sm-12 col-md-4 col-xl-4">
            <b>Tarih:</b>{{ item.date }}
          </div>
        </div>
      </div>
    </router-link>
    <div class="center-block text-center">
      <Pagination
        :currentpage="currentpage"
        :pagesize="pagesize"
        :preload="'/profile/appointments?page='"
      />
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
import Pagination from "../../../../../components/Pagination.vue";
export default {
  props: ["sStatus"],
  components: { Pagination },
  created(){
    this.selectedStatus=this.sStatus
    this.getAppoinementList();
  },
  data() {
    return {
      hourList: [],
      list: [],
      selectedId: 0,
      currentpage: 1,
      page: 1,
      perpage: 10,
      totalsize: 0,
      pagesize: 0,
      isLoading: true,
      selectedStatus: 0,
      onlineData: [],
    };
  },
  watch:{
    "sStatus":function(){
        this.selectedStatus=this.sStatus
        this.getAppoinementList();
    },
    "$route.query.page":function(){
      this.currentpage=this.$route.query.page;
      this.getAppoinementList();
    }
  },
  methods:{
    openVideoCalling(item) {
      let info = {
        id: item.id,
      };
      this.$store.dispatch("videoCallingGetInfo", info).then((value) => {
        this.onlineData = value.data;
      });
    },
    getAppoinementList() {
      this.list = [];
      if (this.$route.params.page) {
        this.currentpage = this.$route.params.page;
      }
      let info = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        selectedStatus: this.selectedStatus,
      };
      this.$store.dispatch("myAppointmentList", info).then((value) => {
        this.list = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
        this.isLoading = false;
      });
    },
    searchAppointment() {
      this.list = [];
      this.isLoading = true;
      let info = {
        begin: 0,
        perpage: this.perpage,
        searchValue: document.getElementById("searchAppointment").value
      };
      this.$store.dispatch("myAppointmentList", info).then((value) => {
        this.list = value.list;
      });
    },
  }
};
</script>